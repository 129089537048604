import { createSelector } from 'reselect';
import { getSelectedDayLogs } from '../../../reducers/orm/daylog/daylog.service';
import { dayList, parseDate, periodFilter } from '../../../shared/date.helper';
import { DaylogModel } from '../../../reducers/orm/daylog/daylog.model';
import keyBy from 'lodash-es/keyBy';
import set from 'lodash-es/set';
import { getPermissionState, hasPermission } from '../../../reducers/auth/permission.helper';
import { defaultDayLog } from '../../../reducers/orm/daylog/daylog.helper';
import { getSelectedSchedulePeriod } from '../../../reducers/page-filters/page-filters.helper';
import { getDepartments } from '../../../reducers/orm/department/department.service';
import { PublishedData, PublishedDepDays } from './schedule.interfaces';
import { DepartmentModel } from '../../../reducers/orm/department/department.model';
import { addDays, isBefore, isEqual } from 'date-fns';

export const getDayLogsForSchedule = createSelector(
  getSelectedSchedulePeriod,
  getSelectedDayLogs,
  (period, dayLogs) => dayLogs.filter(periodFilter(period.minDate, period.maxDate))
);

const daylogMap = (daylog: DaylogModel, canPublish: boolean, published: boolean): PublishedData => {
  let isPublished: boolean;

  if (published) {
    isPublished = true;
    canPublish = false;
  } else {
    isPublished = daylog.published_schedule;
  }

  return {
    date: daylog.date,
    departmentId: daylog.department_id,
    canPublish,
    isPublished,
    saving: daylog.saving || false,
    expected_turnover: daylog.expected_turnover === null ? 0 : parseFloat(daylog.expected_turnover),
  };
};

export const determineOpenCloseDays = (
  daylogs: DaylogModel[],
  minDate: string,
  maxDate: string,
  departments: DepartmentModel[],
  permissionState
): PublishedDepDays => {
  //get daylogs per department per day
  const daylogsPerDepDay = keyBy(daylogs, (daylog: DaylogModel) => `${daylog.department_id}|${daylog.date}`);

  const days = dayList(minDate, maxDate);

  const openCloseDays = {};

  departments.forEach((department) => {
    const departmentId = department.id;

    const canPublish = hasPermission(
      {
        departments: departmentId,
        permissions: ['Create roster'],
      },
      permissionState
    );

    days.forEach((day) => {
      const depDayKey = `${departmentId}|${day}`;
      const dayLog = daylogsPerDepDay[depDayKey] || defaultDayLog(day, departmentId);

      set(openCloseDays, [departmentId, day], daylogMap(dayLog, canPublish, isDatePublished(day, department)));
    });
  });

  return openCloseDays;
};

export const getPublishedForPeriod = createSelector(
  getDepartments,
  getPermissionState,
  getSelectedSchedulePeriod,
  getDayLogsForSchedule,
  (departments, permissionState, period, daylogs: DaylogModel[]): PublishedDepDays => {
    const minDate = period.minDate;
    const maxDate = period.maxDate;
    return determineOpenCloseDays(daylogs, minDate, maxDate, departments, permissionState);
  }
);

export function isDatePublished(dateString: string, department: DepartmentModel) {
  const date = parseDate(dateString);
  const publishDate = addDays(new Date(), parseInt(department.publish_schedules, 10));

  return isBefore(date, publishDate) || isEqual(date, publishDate);
}
